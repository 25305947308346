import urls from "./urls";
import api from "./instance";

const { movieDB } = urls;

export default {
  getKidsKlubUsers: async (token, page, searchQuery) =>
    api.get(
      `/admin/all-users?page=${page}&perPage=10&search_query=${searchQuery}&forKidsClub=true`,
      {
        headers: { Authorization: token }
      }
    ),
  resetPasswordInitUser: email =>
    api.post(`/auth/forgot-password`, { email }).then(res => res),
  getUserList: async (token, page, searchQuery) =>
    api.get(
      `/subscriptions/user-type?perPage=10&page=${page}&search_query=${searchQuery}`,
      {
        headers: { Authorization: token }
      }
    ),
  getUsersType: async (token, page, activeStatus, searchQuery) =>
    api.get(
      `/subscriptions/user-type?perPage=10&page=${page}&type=${activeStatus}&search_query=${searchQuery}`,
      {
        headers: { Authorization: token }
      }
    ),
  getGuestUsers: async (token, page, searchQuery) =>
    api.get(
      `/purchase_history/guest-users?perPage=10&page=${page}&search=${searchQuery}`,
      {
        headers: { Authorization: token }
      }
    ),
  findUsers: async (token, keyword) =>
    api.get(`/admin/find-users?keyword=${keyword}`, {
      headers: { Authorization: token }
    }),
  getUnsubscribersList: async token => {
    //get users and get tiers. creat an object from users list that has the name of their subscripstion tier assigned to it.
    const users = api.get(`/churns?$include=user`, {
      headers: { Authorization: token }
    });
    const data = Promise.all([users]);

    return data;
  },
  getAdminList: async token => {
    //get admins
    const data = api.get(`/admin/fetch-admins`, {
      headers: { Authorization: token }
    });

    return data;
  },
  getUserRoles: async token => {
    const res = await api.get(`/roles?$include=permissions`, {
      headers: { Authorization: token }
    });
    return res;
  },
  getAllPermissions: async token => {
    const res = await api.get(`/permissions`, {
      headers: { Authorization: token }
    });
    return res;
  },
  getRoleInfo: async (id, token) => {
    const res = await api.get(`/roles/${id}?$include=permissions`, {
      headers: { Authorization: token }
    });
    return res;
  },
  getUserInfo: async (id, token) =>
    await api.get(`/admin/customers/${id}`, {
      headers: { Authorization: token }
    }),
  getUserConcessionsHistory: async (id, token) =>
    await api.get(`/admin/customers/${id}/concession-purchases`, {
      headers: { Authorization: token }
    }),
  getUserTicketsHistory: async (id, token) =>
    await api.get(`/admin/customers/${id}/ticket-purchases`, {
      headers: { Authorization: token }
    }),
  getUserSubscriptionsHistory: async (id, token) =>
    await api.get(`/subscriptions/customers/${id}`, {
      headers: { Authorization: token }
    }),
  getGuestUserInfo: async (email, page, token) =>
    await api.get(
      `/analytics/guest-users/booking-activity/${email}?page=${page}&perPage=15`,
      {
        headers: { Authorization: token }
      }
    ),
  renewUserSubscription: async (id, token) =>
    await api.post(
      `/subscriptions/renew-subscription/${id}`,
      {},
      {
        headers: { Authorization: token }
      }
    ),
  handleUploadPhoto: (body, id, token) =>
    api
      .patch(`${movieDB}/admin/profile-picture/${id}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token
        }
      })
      .then(res => res),
  createUser: async (token, payload) => {
    const res = await api.post(`/admin/create-customer`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  editUser: async (token, payload, id) => {
    const res = await api.put(`/admin/edit-profile/${id}`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  clearUserToken: async (token, id) => {
    const res = await api.delete(`/admin/customer/${id}`, {
      headers: { Authorization: token }
    });
    return res;
  },
  createAdmin: async (token, payload) => {
    const res = await api.post(`/admin/create-admin`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  deleteUserCard: async (id, token) => {
    const res = await api.delete(`/flutterwave/payment-token/${id}`, {
      headers: { Authorization: token }
    });
    return res;
  },
  editAdmin: async (token, payload, id) => {
    console.log("working");
    const res = await api.put(`/admin/edit-admin/${id}`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  deleteAdmin: async (adminId, token) => {
    const res = await api.delete(`/users/${adminId}`, {
      headers: { Authorization: token }
    });
    return res;
  },
  createRole: async (token, payload) => {
    const res = await api.post(`/roles`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  patchRole: async (token, payload, id) => {
    const res = await api.patch(`/roles/${id}`, payload, {
      headers: { Authorization: token }
    });
    return res;
  },
  deleteRole: async (id, token) => {
    const res = await api.delete(`/roles/${id}`, {
      headers: { Authorization: token }
    });
    return res;
  }
};

// import api from "api";
// import urls from "./urls";

// const { movieDB } = urls;

// export default {
//   resetPasswordInitUser: email =>
//     api.post(`/auth/forgot-password`, { email }).then(res => res),
//   getUserList: async (token, page, searchQuery) =>
//     api.get(
//       `/subscriptions/user-type?perPage=10&page=${page}&search_query=${searchQuery}`,
//       {
//         headers: { Authorization: token }
//       }
//     ),
//   getUsersType: async (token, page, activeStatus, searchQuery) =>
//     api.get(
//       `/subscriptions/user-type?perPage=10&page=${page}&type=${activeStatus}&search_query=${searchQuery}`,
//       {
//         headers: { Authorization: token }
//       }
//     ),
//   getGuestUsers: async (token, page, searchQuery) =>
//     api.get(
//       `/purchase_history/guest-users?perPage=10&page=${page}&search=${searchQuery}`,
//       {
//         headers: { Authorization: token }
//       }
//     ),
//   findUsers: async (token, keyword) =>
//     api.get(`/admin/find-users?keyword=${keyword}`, {
//       headers: { Authorization: token }
//     }),
//   getUnsubscribersList: async token => {
//     //get users and get tiers. creat an object from users list that has the name of their subscripstion tier assigned to it.
//     const users = api.get(`/churns?$include=user`, {
//       headers: { Authorization: token }
//     });
//     const data = Promise.all([users]);

//     return data;
//   },
//   getAdminList: async token => {
//     //get admins
//     const data = api.get(`/admin/fetch-admins`, {
//       headers: { Authorization: token }
//     });

//     return data;
//   },
//   getUserRoles: async token => {
//     const res = await api.get(`/roles?$include=permissions`, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   getAllPermissions: async token => {
//     const res = await api.get(`/permissions`, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   getRoleInfo: async (id, token) => {
//     const res = await api.get(`/roles/${id}?$include=permissions`, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   getUserInfo: async (id, token) =>
//     await api.get(`/admin/get-customer/${id}`, {
//       headers: { Authorization: token }
//     }),
//   getGuestUserInfo: async (email, page, token) =>
//     await api.get(
//       `/purchase_history/guest-users/booking-activity/${email}?page=${page}&perPage=15`,
//       {
//         headers: { Authorization: token }
//       }
//     ),
//   renewUserSubscription: async (id, token) =>
//     await api.post(
//       `/subscriptions/renew-subscription/${id}`,
//       {},
//       {
//         headers: { Authorization: token }
//       }
//     ),
//   handleUploadPhoto: (body, id, token) =>
//     api
//       .patch(`${movieDB}/admin/profile-picture/${id}`, body, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: token
//         }
//       })
//       .then(res => res),
//   createUser: async (token, payload) => {
//     const res = await api.post(`/admin/create-customer`, payload, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   editUser: async (token, payload, id) => {
//     const res = await api.put(`/admin/edit-profile/${id}`, payload, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   clearUserToken: async (token, id) => {
//     const res = await api.delete(`/admin/customer/${id}`, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   createAdmin: async (token, payload) => {
//     const res = await api.post(`/admin/create-admin`, payload, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   deleteUserCard: async (id, token) => {
//     const res = await api.delete(`/flutterwave/payment-token/${id}`, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   editAdmin: async (token, payload, id) => {
//     console.log("working");
//     const res = await api.put(`/admin/edit-admin/${id}`, payload, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   deleteAdmin: async (adminId, token) => {
//     const res = await api.delete(`/users/${adminId}`, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   createRole: async (token, payload) => {
//     const res = await api.post(`/roles`, payload, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   patchRole: async (token, payload, id) => {
//     const res = await api.patch(`/roles/${id}`, payload, {
//       headers: { Authorization: token }
//     });
//     return res;
//   },
//   deleteRole: async (id, token) => {
//     const res = await api.delete(`/roles/${id}`, {
//       headers: { Authorization: token }
//     });
//     return res;
//   }
// };
