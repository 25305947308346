import React from "react";
import KidsKlub from "./KidsKlub";

export default [
  {
    path: "/dashboard/kids-klub",
    exact: true,
    component: () => <KidsKlub />
  }
];
