import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import userApis from "../../../api/users-api";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Grid } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import { Alert } from "@material-ui/lab";
import PaperedInput from "../../../components/Inputs/PaperedInput";
import { debounce } from "../../../utils/functions";

const KidsKlubList = ({ jwt }) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [error, setError] = useState({
    isError: false,
    message: ""
  });
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setLoading(true);
    setError({ isError: false, message: "" });
    userApis
      .getKidsKlubUsers(`Bearer ${jwt}`, page + 1, searchInput)
      .then(res => {
        const { users = [], totalItems = 0 } = res?.data?.data?.data;
        console.log(users);
        setLoading(false);
        setData(
          users?.map((item, index) => ({
            date: `${item?.createdAt?.substring(
              0,
              10
            )} ${item?.createdAt?.substring(11, 19)}`,
            email: item?.email,
            name: `${item?.firstName} ${item?.lastName}`,
            cardNumber: item?.rosettaCardNumber,
            mobile: item?.mobile,
            id: index
          }))
        );
        setTotalData(totalItems);
      })
      .catch(e => {
        setError({ isError: true, message: "There is an error" });
        setLoading(false);
      });
  }, [page, jwt, searchInput]);

  const columns = [
    { field: "cardNumber", headerName: "ID", width: 150 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "mobile", headerName: "Phone Number", width: 280 },
    { field: "date", headerName: "Date Added ", width: 200 }
  ];

  return (
    <div>
      <>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <PaperedInput
              onChange={e => {
                e.persist();
                debounce(() => setSearchInput(e.target.value), 2000);
              }}
              placeholder="Search for User"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid justify="flex-end" container>
              <CSVLink data={data} filename={"guest-users.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon className="c-user-icon" /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              autoHeight={true}
              loading={loading}
              rowCount={totalData}
              page={page}
              onPageChange={page => {
                setPage(page);
              }}
              paginationMode="server"
            />
          )}
        </div>
      </>
    </div>
  );
};

const mapStateToProps = state => ({
  jwt: state.user.user.jwt
});

export default connect(mapStateToProps)(withRouter(KidsKlubList));
